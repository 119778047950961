import { useState } from "react";
import { createCryptoKey } from "../helpers/createCryptoKey";

export function useDecryptedClientId() {
  const [decryptedClientId, setDecryptedClientId] = useState("");
  const [error, setError] = useState();

  const decryptClientId = async (encryptedData, secretKey) => {
    setError(null);

    // Преобразуем зашифрованное сообщение в байты
    const encryptedBytes = Uint8Array.from(atob(encryptedData), (c) => c.charCodeAt(0));

    // Извлекаем IV и зашифрованный текст
    const ivLength = encryptedBytes[0]; // IV длиной 12 байт
    const iv = encryptedBytes.slice(1, ivLength + 1);

    const tagLength = encryptedBytes[1 + ivLength];
    const tag = encryptedBytes.slice(ivLength + 2, ivLength + 2 + tagLength);
    const cipherText = encryptedBytes.slice(ivLength + tagLength + 2);
    const cipherTextWithTag = Uint8Array.from([...cipherText, ...tag]);

    try {
      // Создаем CryptoKey
      const cryptoKey = await createCryptoKey(secretKey);

      // Расшифровываем сообщение
      const decryptedBuffer = await crypto.subtle.decrypt(
        {
          name: "AES-GCM",
          iv: iv,
        },
        cryptoKey,
        cipherTextWithTag,
      );

      // Преобразуем расшифрованные данные в строку
      const decryptedText = new TextDecoder().decode(decryptedBuffer);
      setDecryptedClientId(decryptedText);
    } catch (err) {
      setError(err.message);
    }
  };

  return { decryptedClientId, error, decryptClientId };
}
