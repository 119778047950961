import "./place-modal.scss";
import { useContext } from "react";
import { Context } from "../../App";
import close from "../../assets/img/icons/close.svg";
import { useClickOutside } from "../../hook/useClickOutside";

export const PlaceModal = ({ dataModal, modalOpen }) => {
  const { modal, refMouse, setModal } = useContext(Context);
  const formatDate = dataModal?.date_end?.split("-");
  const newFormat = dataModal?.date_end && `${formatDate[2]}.${formatDate[1]}.${formatDate[0]}`;

  useClickOutside(refMouse, () => {
    if (modal) {
      setTimeout(() => {
        setModal(false);
      }, 50);
    }
  });

  let workTimes = [];
  if (modal && Array.isArray(dataModal.address)) {
    if (dataModal.address.length > 0 && "work_time" in dataModal.address[0]) {
      workTimes = Object.values(dataModal?.address[0].work_time);
    }
  }
  const hasWorkTime = modal && workTimes.length > 0 && workTimes.some((el) => el.length > 0);

  return (
    <div
      className={!modal ? "place-modal" : "place-modal active"}
      onClick={() => {
        if (document.body.classList.contains("noscroll")) {
          document.body.classList.remove("noscroll");
        }
        setModal(false);
      }}
    >
      <div className="place-modal__body">
        <div className="place-modal__content" ref={refMouse} onClick={(e) => e.stopPropagation()}>
          {dataModal && (
            <>
              {dataModal.background_image && (
                <div className="place-modal__image">
                  <img src={dataModal.background_image} alt={dataModal.name} />
                  {dataModal.image && (
                    <img
                      className="place-modal__cat-img"
                      src={dataModal.image}
                      alt={dataModal.short_description}
                    />
                  )}
                </div>
              )}
              <div className="place-modal__info">
                <h3 className="place-modal__title">{dataModal.name || dataModal.title}</h3>
                {dataModal.category && (
                  <span className="place-modal__category">{dataModal.category.title}</span>
                )}
                {dataModal.cashback && (
                  <div className="place-modal__cashback">
                    Кешбэк {dataModal.cashback}% при оплате Картой жителя Татарстана
                  </div>
                )}
                {dataModal?.date_end && (
                  <div className="place-modal__short">Акция действует до {newFormat}</div>
                )}
                {dataModal.rules &&
                  dataModal.rules.map(({ payment_size, description }, ind) => {
                    return (
                      <div className="place-modal__cashback" key={ind}>
                        Кешбэк {payment_size} {description}
                      </div>
                    );
                  })}

                {(dataModal.rate_cost || dataModal.kitchen) && (
                  <div className="place-modal__inner-attr">
                    {dataModal.rate_cost && (
                      <div className="place-modal__attr">Средний чек: {dataModal.rate_cost} ₽</div>
                    )}
                    {dataModal.kitchen && (
                      <div className="place-modal__attr">Кухня: {dataModal.kitchen}</div>
                    )}
                  </div>
                )}
                {dataModal.description && (
                  <div
                    className="place-modal__description"
                    dangerouslySetInnerHTML={{ __html: dataModal.description }}
                  ></div>
                )}

                {dataModal?.short_description && (
                  <div className="place-modal__short">
                    Категория: {dataModal?.short_description}
                  </div>
                )}
                {dataModal?.address && (
                  <div className="place-modal__contacts">
                    {dataModal?.address[0]?.address &&
                      dataModal?.address.map(({ coords, address }, ind) => {
                        return (
                          <a
                            key={ind}
                            href={`https://yandex.ru/maps/?ll=${coords.lon},${coords.lat}&z=17&l=map`}
                            target="_blank"
                            className="place-modal__contact place-modal__contact_map"
                          >
                            {address}
                          </a>
                        );
                      })}
                    {typeof dataModal.address === "string" && (
                      <a
                        href={`https://yandex.ru/maps/?ll=${dataModal.lon},${dataModal.lat}&z=17&l=map`}
                        target="_blank"
                        className="place-modal__contact place-modal__contact_map"
                      >
                        {dataModal.address}
                      </a>
                    )}

                    {hasWorkTime && dataModal?.address[0] && dataModal?.short_description && (
                      <div className="place-modal__contact place-modal__contact_time">
                        {Object.keys(dataModal?.address[0].work_time).map((el, ind) => {
                          const isWorkTime = dataModal?.address[0].work_time[el];
                          return (
                            <span key={ind}>
                              {(isWorkTime && ind === 0 && "пн " + isWorkTime + ";") ||
                                (isWorkTime && ind === 1 && "вт " + isWorkTime + ";") ||
                                (isWorkTime && ind === 2 && "ср " + isWorkTime + ";") ||
                                (isWorkTime && ind === 3 && "чт " + isWorkTime + ";") ||
                                (isWorkTime && ind === 4 && "пт " + isWorkTime + ";") ||
                                (isWorkTime && ind === 5 && "сб " + isWorkTime + ";") ||
                                (isWorkTime && ind === 6 && "вс " + isWorkTime + ";")}
                            </span>
                          );
                        })}
                      </div>
                    )}
                    {dataModal?.address[0] &&
                      dataModal?.address[0]?.phones &&
                      dataModal?.address[0]?.phones?.map(({ phone }, ind) => {
                        return (
                          <a
                            key={ind}
                            href={`tel:${phone.replace(new RegExp("[- )(]", "gm"), "")}`}
                            className="place-modal__contact place-modal__contact_phone"
                          >
                            {phone}
                          </a>
                        );
                      })}

                    {dataModal?.phone && (
                      <a
                        href={`tel:${dataModal?.phone.replace(new RegExp("[- )(]", "gm"), "")}`}
                        className="place-modal__contact place-modal__contact_phone"
                      >
                        {dataModal?.phone}
                      </a>
                    )}

                    {dataModal.site_url && (
                      <a
                        href={dataModal.site_url}
                        // target="_blank"
                        className="place-modal__contact place-modal__contact_site_url"
                      >
                        {dataModal.site_url}
                      </a>
                    )}
                  </div>
                )}
                {(dataModal.work_time || dataModal.site) && (
                  <div className="place-modal__contacts">
                    {dataModal?.work_time && (
                      <div className="place-modal__contact place-modal__contact_time place-modal__contact_front">
                        <span>{dataModal?.work_time}</span>
                      </div>
                    )}
                    {dataModal.site && (
                      <a
                        href={dataModal.site}
                        // target="_blank"
                        className="place-modal__contact place-modal__contact_site_url"
                      >
                        {dataModal.site}
                      </a>
                    )}
                  </div>
                )}
                {dataModal.ref_link && (
                  <a href={dataModal.ref_link} className="place-modal__referral-link">
                    Перейти
                  </a>
                )}
              </div>
              <button className="place-modal__close" onClick={modalOpen}>
                <img src={close} alt="closed" />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
